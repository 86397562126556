import '../styles/styles.scss';
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

// import '../../node_modules/prettier-plugin-css-order/src/main.mjs';
// import '../../node_modules/prettier-plugin-tailwindcss/dist/index.mjs';

// import App from './app';

import './general';
// import './swiper';

// App.init();
//
